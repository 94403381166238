import { ActivityContact } from './contact'
import { Listing, ListingBroker } from './listing'
import { ModelOrganization } from './organization'
import { ModelUser, TokenUser, User } from './user'

export enum ActivitySource {
  admin = 'admin',
  app = 'app',
}

export enum ActivityType {
  create = 'create',
  update = 'update',
  delete = 'delete',
  view = 'view',
  preview = 'preview',
  download = 'download',
  import = 'import',
  contact = 'contact',
  login = 'login',
  viewLink = 'viewLink',
  notification = 'notification',
}

export enum ActivityRelatedType {
  organization = 'organization',
  broker = 'broker',
  contact = 'contact',
  contactList = 'contactList',
  company = 'company',
  dataLayer = 'dataLayer',
  dataLayerEntry = 'dataLayerEntry',
  email = 'email',
  listing = 'listing',
  memberRequest = 'memberRequest',
  report = 'report',
  search = 'search',
  user = 'user',
}

export type Activity = {
  _id: string
  tid: string
  organization: ModelOrganization
  created: string
  user?: TokenUser
  historyId?: string
  source: ActivitySource
  type: ActivityType
  relatedType: ActivityRelatedType
  related: {
    _id: string
    tid: string
    slug: string
    name?: string
    firstName?: string
    lastName?: string
    organization: ModelOrganization
  }
  contact?: ActivityContact
  brokers?: (ListingBroker | User | ModelUser)[]
  meta?: { [key: string]: any }
  referer?: string
  geolocation?: {
    city: string
    stateAbbr?: string
    country: string
    latitude: string
    longitude: string
  }
}

export type ActivityData = Pick<
  Activity,
  | 'organization'
  | 'source'
  | 'type'
  | 'relatedType'
  | 'related'
  | 'contact'
  | 'brokers'
  | 'meta'
  | 'historyId'
>

export function excludeActivityCompanyUsers(
  activity: Activity,
  listing: Listing
) {
  if (
    ![ActivityType.create, ActivityType.update, ActivityType.delete].includes(
      activity.type
    ) &&
    listing.company.tid === activity.user?.company?.tid
  ) {
    return false
  }
  return true
}
